import { useEffect, useState } from "react";
import PartySocket from "partysocket";
import usePartySocket from "partysocket/react";

// We're not using server-side rendering and initial props in this
// example (our app is client-side only), so loadInitialCount is not
// used. But if we were using SSR, we would use this function to load
// the initial count, as returned by onRequest in `party/server.ts`.
export async function loadInitialCount(host: string) {
	const initialCount = await PartySocket.fetch(
		{
			host,
			party: "counter",
			room: "index",
		},
		{
			method: "GET",
		},
	).then((res) => res.text());
	return parseInt(initialCount) || 0;
}

export default function Counter() {
	const [message, setMessage] = useState<string | null>(null);

	const socket = usePartySocket({
		// host defaults to the current URL if not set
		// we could use any room name here
		room: "example-room",
		onMessage(evt) {
			setMessage(evt.data);
		},
	});

	return (
		<div>
			<textarea
				style={{ fontFamily: "monospace", minWidth: "100%", maxWidth: "100%", height: "400px" }}
				value={message ?? ""}
				onChange={(e) => {
					setMessage(e.target.value);
					socket.send(e.target.value);
				}}
			/>
		</div>
	);
}
